header {
  background-color: white;
  color: rgb(40, 40, 40);
  font-weight: 500;
  display: unset;
}

.topBar {
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  height: 60px;
  z-index: 30;
  padding-right: 14px;
  padding-left: 14px;
}

#adDiv {
  background-color: #aadb1e;
}

#adComment {
  font-size: 20px;
  font-weight: 600;
}

#adGoTo {
  font-size: 17px;
}

#adGoTo span {
  font-weight: 800;
}

.topAd {
  padding-left: 14px;
  padding-right: 14px;
  background-color: #aadb1e;
  height: 50px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: auto;
  align-items: center;
}

.scrolledStickyDiv {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
}

.stickyDiv {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
  border-bottom: 1px solid rgb(230, 230, 230);
}

#topBarDiv {
  display: flex;
  justify-content: space-between;
  width: 170px;
}

#topBarDiv #signIn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 80px;
  height: 32px;
  font-size: 15px;
  font-weight: 500;
}

#topBarDiv #signUp {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #aadb1e;
  width: 80px;
  height: 34px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 6px;
  transition: 300ms;
}

#topBarDiv #signUp:hover {
  background-color: #9cce00;
  transition: 300ms;
}
